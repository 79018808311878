import usersEndpoints from "./endpoints/users-endpoints";
import apiConfig from "../config/api";
import http from "./http";

const { getUsers, getUser, getUserLogs } = usersEndpoints;
const { baseURL } = apiConfig;

const Users = {
  async getAllUsers() {
    const getAllUsersUrl = `${baseURL}${getUsers.GET()}`;

    return await http
      .get(
        getAllUsersUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },

  async getUserDetails(userId) {
    const getUserUrl = `${baseURL}${getUser.GET(userId)}`;

    return await http
      .get(
        getUserUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },

  async getUserLogs(userId) {
    const getUserLogsUrl = `${baseURL}${getUserLogs.GET(userId)}`;

    return await http
      .get(
        getUserLogsUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default Users;
