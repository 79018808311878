<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      <b-button class="button is-primary" :disabled="true">
        Utilizator nou
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Utilizatori"
        icon="file-multiple"
      >
        <users-table :users="users" />
      </card-component>
      <hr />
    </section>
  </div>
</template>

<script>
import UsersService from "@/services/users.service";
const _ = require("lodash");

import UsersTable from "@/components/admin/users/users-table";
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
export default {
  name: "Users",
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    UsersTable,
  },
  data() {
    return {
      users: [],
    };
  },
  async created() {
    try {
      const [usersResponse] = await Promise.all([this.getUsersInformation()]);
      this.users = usersResponse;
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getUsersInformation() {
      const { getAllUsers } = UsersService;
      const response = await getAllUsers();
      const { data } = response;
      return data;
    },
  },
  computed: {
    titleStack() {
      return ["Utilizatori", "Utilizatori"];
    },
    heroRouterLinkTo() {
      return { name: "/sale/add" };
    },
  },
};
</script>
