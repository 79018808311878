<template>
  <b-table
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    default-sort="name"
    :data="users"
  >
    <b-table-column
      label="Utilizator"
      field="nickname"
      sortable
      v-slot="props"
      centered
    >
      {{ props.row.nickname }}
    </b-table-column>
    <b-table-column
      label="Email"
      field="email"
      sortable
      v-slot="props"
      centered
    >
      <div>
        {{ props.row.email }}
      </div>
    </b-table-column>
    <b-table-column
      label="Ultimul IP de conectare"
      field="lastIp"
      sortable
      centered
    >
      <template v-slot:header="{ column }">
        <b-tooltip
          label="Adresa IP a dispozitivului de la care utilizatorul s-a autentificat ultima dată"
          append-to-body
          dashed
        >
          {{ column.label }}
        </b-tooltip>
      </template>
      <template v-slot="props">
        {{ props.row.lastIp }}
      </template>
    </b-table-column>
    <b-table-column
      label="Ultima dată de conectare"
      field="lastIp"
      sortable
      centered
    >
      <template v-slot:header="{ column }">
        <b-tooltip
          label="Ultima dată la care utilizatorul s-a conectat"
          append-to-body
          dashed
        >
          {{ column.label }}
        </b-tooltip>
      </template>
      <template v-slot="props">
        {{ props.row.lastLogin | formatDateTime }}
      </template>
    </b-table-column>
    <b-table-column label="Management și activitate" v-slot="props" centered>
      <div>
        <router-link
          :to="{ name: 'user.details', params: { id: props.row.userId} }"
          class="button is-small is-primary"
        >Detalii
        </router-link>
      </div>
    </b-table-column>
    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="isLoading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Se incarca datele...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nu exista inregistrari</p>
        </template>
      </div>
    </section>
  </b-table>
</template>
<script>
export default {
  name: "UsersTable",
  props: {
    users: Array,
    default: null,
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>
