export default Object.freeze({
  getUsers: {
    GET: () => {
      return `Auth/users`;
    },
  },
  getUser: {
    GET: (userId) => {
      return `Auth/users/${userId}`;
    },
  },
  getUserLogs:{
    GET: (userId) => {
      return `Auth/users/${userId}/logs`;
    }
  }
});
